<!-- 用能报表 -->
<template>
  <div id="energy-report">
    <!-- <div class="tree">
      <div class="tree-title">变配电站</div>
      <el-tree
        ref="tree"
        :default-expand-all="false"
        :show-checkbox="false"
        :data="treeData"
        :check-on-click-node="false"
        :props="defaultProps"   
        node-key="id"
        @node-click="handleCheck"
      ></el-tree>
    </div> -->
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>用能报表</span>
      </div>
      <div class="content">
        <ul class="tabs">
          <li v-for="(item, index) in tabsData" :key="index" :class="select === index ? 'select' : ''"
            @click="switchTabs(index)" style="cursor: pointer;">
            {{ item }}
          </li>
        </ul>
        <div class="query-form">
          <el-form class="form" ref="form" :inline="true" :model="formData">
            <el-form-item label="日期" class="query-date">
              <el-date-picker v-show="timeType=='date'" :append-to-body="false" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd" v-model="formData.time"></el-date-picker>
              <el-date-picker v-show="timeType=='month'" :append-to-body="false" placeholder="请选择日期" type="month" value-format="yyyy-MM-dd" v-model="formData.time"></el-date-picker>
              <el-date-picker v-show="timeType=='year'" :append-to-body="false" placeholder="请选择日期" type="year" value-format="yyyy-MM-dd" v-model="formData.time"></el-date-picker>
            </el-form-item>
            <el-form-item>
              <div class="flex-row" v-if="select==0">
                <div class="hour">
                  <el-select :popper-append-to-body="false" v-model="formData.hourSta" placeholder="">
                    <el-option v-for="item in hourStaList" :key="item.name" :label="item.name" :value="item.value"></el-option>
                  </el-select>
                  <span>时~</span>
                </div>
                <div class="hour">
                  <el-select :popper-append-to-body="false" v-model="formData.hourEnd" placeholder="">
                    <el-option v-for="item in hourEndList" :key="item.name" :label="item.name" :value="item.value"></el-option>
                  </el-select>
                  <span style="margin-left: 9px">时</span>
                </div>
              </div>
            </el-form-item>
            <el-form-item>
              <!-- <el-button  type="primary" @click="previousYear(0)" icon="el-icon-arrow-left">上一日</el-button>
              <el-button type="primary" @click="previousYear(1)">下一日<i class="el-icon-arrow-right el-icon--right"></i></el-button> -->
              <el-button type="primary" @click="getEnergyList" icon="el-icon-search">查询</el-button>
              <!-- <el-button type="primary" @click="viewBar">图表</el-button>
              <el-button type="primary" @click="viewPie">饼图</el-button> -->
              <!-- <el-button
                type="primary"
                icon="el-icon-download"
                @click="exportDialog = true"
                >导出</el-button
              > -->
              <!-- <span class="tip">(*为进线回路)</span> -->
            </el-form-item>
          </el-form>
        </div>

        <el-table class="table" :data="tableData" ref="unbb" v-loading='dataLoading' element-loading-text="加载中" element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.3)">
    <el-table-column prop="loopName" label="回路名称" min-width="250" align="center">
      <template slot-scope="scope">
              {{ scope.row.loopName }}  ({{ scope.row.loopNumber }})
            </template>
    </el-table-column>
    <el-table-column align="center" v-for="(item,index) in tableData[0].powerList" :key="item.ts" :width="select==0?'100':'130'">
      <template slot="header">
        {{ timeSP(item.ts)}}
        <!-- {{ scope.row.powerList[scope.$index].ts }} -->
      </template>
      <template slot-scope="scope">
        <!-- {{scope.row.powerList[scope.$index].dianNeng }} -->
        {{ scope.row.powerList[index].dianNeng }}
        <!-- {{  Math.ceil(scope.row.powerList[index].dianNeng * 1000) / 1000 }} -->
      </template>
    </el-table-column>
  </el-table>
      
        
      </div>
    </div>
    <!-- 折线图 -->
    <el-dialog :title="operation === 0 ? '用能折线图' : '用能饼图'" :visible.sync="chartDialog" width="45%" destroy-on-close>
      <div id="chart"></div>
    </el-dialog>
    <el-dialog title="导出" :visible.sync="exportDialog" width="20%">
      <span class="dialog-content">确定要导出数据吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="exportDialog = false">取 消</el-button>
        <el-button type="primary" @click="exportDialog = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import * as echarts from "echarts";
import { emissionsCarbonApi } from '@/api/emissionsCarbon.js'
import { nextTick } from "vue";
export default {
  name: "",

  data() {
    return {
      formData: {
        time: "",
        hourSta:'',
        hourEnd:'',
      },
      dataLoading:false,
      operation: 1,
      chartDialog: false,
      exportDialog: false,
      tabsData: ["日报", "月报", "年报"],
      tableData: [
        {
          name: "*主进线柜",
          zero: "20",
          one: "50",
          two: "42",
          three: "40",
          four: "50",
          five: "42",
          six: "40",
          seven: "50",
          eight: "42",
          nine: "50",
          ten: "42",
          eleven: "50",
          twelve: "42",
        },
        {
          name: "配电室",
          zero: "20",
          one: "0.24",
          two: "0.24",
          three: "40",
          four: "0.24",
          five: "0.24",
          six: "40",
          seven: "50",
          eight: "0.24",
          nine: "0.24",
          ten: "42",
          eleven: "0.24",
          twelve: "42",
        },
        {
          name: "*柱变",
          zero: "40.7",
          one: "33.28",
          two: "32.26",
          three: "40.7",
          four: "33.28",
          five: "32.28",
          six: "40.5",
          seven: "50.5",
          eight: "42.5",
          nine: "50.5",
          ten: "42.2",
          eleven: "50.23",
          twelve: "42.4",
        },
      ],
      select: 0,
      requestParam: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      checkedNodes: [],
      hourStaList:[
        {
          name:'0',
          value:'00:00:00'
        },
        {
          name:'1',
          value:'01:00:00'
        },
        {
          name:'2',
          value:'02:00:00'
        },
        {
          name:'3',
          value:'03:00:00'
        },
        {
          name:'4',
          value:'04:00:00'
        },
        {
          name:'5',
          value:'05:00:00'
        },
        {
          name:'6',
          value:'06:00:00'
        },
        {
          name:'7',
          value:'07:00:00'
        },
        {
          name:'8',
          value:'08:00:00'
        },
        {
          name:'9',
          value:'09:00:00'
        },
        {
          name:'11',
          value:'11:00:00'
        },
        {
          name:'12',
          value:'12:00:00'
        },
        {
          name:'13',
          value:'13:00:00'
        },
        {
          name:'14',
          value:'14:00:00'
        },
        {
          name:'15',
          value:'15:00:00'
        },
        {
          name:'16',
          value:'16:00:00'
        },
        {
          name:'17',
          value:'17:00:00'
        },
        {
          name:'18',
          value:'18:00:00'
        },
        {
          name:'19',
          value:'19:00:00'
        },
        {
          name:'20',
          value:'20:00:00'
        },
        {
          name:'21',
          value:'21:00:00'
        },
        {
          name:'22',
          value:'22:00:00'
        },
        {
          name:'23',
          value:'23:00:00'
        },

      ],
      hourEndList:[
        {
          name:'0',
          value:'00:59:59'
        },
        {
          name:'1',
          value:'01:59:59'
        },
        {
          name:'2',
          value:'02:59:59'
        },
        {
          name:'3',
          value:'03:59:59'
        },
        {
          name:'4',
          value:'04:59:59'
        },
        {
          name:'5',
          value:'05:59:59'
        },
        {
          name:'6',
          value:'06:59:59'
        },
        {
          name:'7',
          value:'07:59:59'
        },
        {
          name:'8',
          value:'08:59:59'
        },
        {
          name:'9',
          value:'09:59:59'
        },
        {
          name:'11',
          value:'11:59:59'
        },
        {
          name:12,
          value:'12:59:59'
        },
        {
          name:13,
          value:'13:59:59'
        },
        {
          name:14,
          value:'14:59:59'
        },
        {
          name:15,
          value:'15:59:59'
        },
        {
          name:16,
          value:'16:59:59'
        },
        {
          name:17,
          value:'17:59:59'
        },
        {
          name:18,
          value:'18:59:59'
        },
        {
          name:19,
          value:'19:59:59'
        },
        {
          name:20,
          value:'20:59:59'
        },
        {
          name:21,
          value:'21:59:59'
        },
        {
          name:22,
          value:'22:59:59'
        },
        {
          name:23,
          value:'23:59:59'
        },

      ],
      timeType:'date',
      staTime:'',
      endTime:'',
      stationNumber:'',
     
    };
  },
created(){
  this.$store.commit('increment', '用电分析')
  this.$store.commit('selectChild', '用能报表')
  this.$store.commit("selectChildren", "");
},
updated(){
  this.$refs['unbb'].doLayout()
},
  mounted() {
    // this.queryOperation();
    this.stationNumber = sessionStorage.getItem('stationNumber')
    // this.getYearStartAndEnd()
    this.getEnergyList()
  },

  methods: {
    handleSpanMethod({ row, column, rowIndex, columnIndex }) {
    if (columnIndex === 0) {
      const rowspan = this.tableData.filter(item => item.loopMame === row.loopMame).length;
      return rowspan;
    }
  },
  formatTime(row) {
    // 在此处可以对时间进行进一步格式化
    return row.ts;
  },
  
    timeSP(item){
      if(this.select==0){
        return item.slice(11,16)
      }else{
        
        return item.slice(0,10)
      }
    },
    search(){
      console.log(this.formData);
    },
    switchTabs(index) {
      this.select = index;
      this.formData.time=''
      if(index==0){
        this.timeType='date'
      }
      if(index==1){
        this.timeType='month'
      }
      if(index==2){
        this.timeType='year'
      }
      this.getEnergyList()
      
    },
    getEnergyList(){
      let type;
      if(this.select==0){
        this.getDayStartAndEnd()
        type=1
      }else if (this.select==1){
        this.getMonthStartAndEnd(this.formData.time)
        type=3
      }else {
        this.getYearStartAndEnd(this.formData.time)
        type=5

      }
      let obj={
        stationNumber:this.stationNumber,
        staTime:this.staTime,
        endTime:this.endTime,
        type:type
      }
      
      this.dataLoading=true

      emissionsCarbonApi.getEnergyList(obj).then(res=>{
        console.log(res);

        if(res.code==200){
          this.tableData=res.data
        }
        this.$nextTick(() => {
        // 在这里执行渲染完成后的代码
        this.dataLoading=false
      });


      })
    },
   
    handleCheck(checkedNodes, event) {
      this.checkedNodes = checkedNodes;
      console.log(this.checkedNodes);
    },
    viewBar() {
      this.operation = 0;
      this.chartDialog = true;
      this.$nextTick(() => {
        this.barChart();
      });
    },
    viewPie() {
      this.operation = 1;
      this.chartDialog = true;
      this.$nextTick(() => {
        this.pieChart();
      });
    },
    barChart() {
      var chart = echarts.init(document.getElementById("chart"));
      // 指定图表的配置项和数据
      var option = {
        tooltip: {},
        legend: {
          show: true,
          bottom: "1%",
          left: "center",
        },
        color: ["#14bf9b", "#e0ab34", "#176ad2", "#9875e3"],
        xAxis: {
          data: [
            "1时",
            "2时",
            "3时",
            "4时",
            "5时",
            "6时",
            "7时",
            "8时",
            "9时",
            "10时",
            "11时",
            "12时",
          ],
          axisLabel: {
            textStyle: {
              color: "#aed6ff", // 设置字体颜色
              fontSize: 12,
            },
          },
        },
        yAxis: {
          name: "单位:km/h",
          nameTextStyle: {
            color: "#aed6ff",
          },
          // data: [0,1000,2000,3000,4000,5000,6000],
          axisLabel: {
            textStyle: {
              color: "#aed6ff", // 设置字体颜色
            },
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            name: "分类1",
            type: "bar",
            barWidth: 10,
            data: [10, 52, 200, 334, 390, 330, 220],
          },
          {
            name: "分类2",
            type: "bar",
            barWidth: 10,
            data: [10, 52, 200, 334, 390, 330, 220],
          },
          {
            name: "分类3",
            type: "bar",
            barWidth: 10,
            data: [10, 52, 200, 334, 390, 330, 220],
          },
          {
            name: "分类4",
            type: "bar",
            barWidth: 10,
            data: [10, 52, 200, 334, 390, 330, 220],
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      chart.setOption(option);
    },
    pieChart() {
      var chart = echarts.init(document.getElementById("chart"));
      // 指定图表的配置项和数据
      var option = {
        title: {
          text: "饼图",
          left: "center",
          textStyle: {
            color: '#fff'
          }
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          left: "left",
          textStyle: {
            color: '#fff'
          }
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: "50%",
            data: [
              { value: 1048, name: "分类1" },
              { value: 735, name: "分类2" },
              { value: 580, name: "分类3" },
              { value: 484, name: "分类4" },
            ],
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      chart.setOption(option);
    },
    //day
    getDayStartAndEnd(){
      const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // 月份从 0 开始，需要加 1；使用 padStart 补齐两位
    const day = String(today.getDate()).padStart(2, '0'); // 使用 padStart 补齐两位
    let sta;
    let end;
    if(this.formData.time){
      
      this.staTime = `${this.formData.time} ${this.formData.hourSta ? this.formData.hourSta:'00:00:00'}` ;
      this.endTime= `${this.formData.time}  ${this.formData.hourEnd ? this.formData.hourEnd:'23:59:59'}`;
    }else{
      this.staTime = `${year}-${month}-${day} ${this.formData.hourSta ? this.formData.hourSta:'00:00:00'}`;
      this.endTime= `${year}-${month}-${day} ${this.formData.hourEnd ? this.formData.hourEnd:'23:59:59'}`;
    }
    console.log('````',this.staTime,this.endTime);
    },
    //month
    getMonthStartAndEnd(dateString) {
      let date;
    if (dateString) {
        date = new Date(dateString);
    } else {
        date = new Date();
    }
    
    const year = date.getFullYear();
    const month = date.getMonth() + 1; 
    const firstDay = new Date(year, month - 1, 2); 
    const lastDay = new Date(year, month, 1); 

    const formatDate = (d) => {
        return d.toISOString().slice(0, 10);
    };
    this.staTime = formatDate(firstDay);
    this.endTime= formatDate(lastDay);
    console.log(this.staTime,this.endTime);
   
    },
    //year
    getYearStartAndEnd(dateString) {
    let date;
    if (!dateString) {
        date = new Date();
    } else {
        date = new Date(dateString);
    }
    this.staTime = new Date(date.getFullYear(), 0, 2).toISOString().slice(0, 10);
    
    this.endTime = new Date(date.getFullYear() + 1, 0, 1).toISOString().slice(0, 10);
    console.log(this.staTime,this.endTime);
    // return {
    //     firstDay: firstDay,
    //     lastDay: lastDay
    // };
    },
  },
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

#energy-report {
  width: 100%;
  min-height: 781px;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
  box-sizing: border-box;
  display: flex;
  height: calc(100vh - 155px);
}

/* tree */
.tree :deep() .el-tree {
  width: 240px;
  color: #aed6ff;
  background-color: rgba(255, 255, 255, 0);
  padding: 0 0 10px 21px;
  box-sizing: border-box;
}

.tree-title {
  font-weight: 700;
  color: #d8e3ff;
  font-size: 14px;
  margin: 24px 0 21px 21px;
}

::v-deep .el-tree {
  background: #03183f !important;
  color: #aed6ff;
}

::v-deep .el-tree-node {
  background: #03183f !important;
  color: #aed6ff;
}

::v-deep .is-current {
  background: #03183f !important;
  color: #ffc74a;
}

::v-deep .is-checked {
  background: #03183f !important;
  /* color: #FFC74A; */
}

::v-deep .el-tree-node__content:hover {
  background: #03183f;
  /* color: #FFC74A; */
}

::v-deep.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
  background-color: #03183f;
}

::v-deep .el-tree-node {
  background-color: #03183f;
}

::v-deep .el-tree-node:focus>.el-tree-node__content {
  background-color: #03183f;
}

.main {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.title {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.title img {
  width: 20px;
  height: 20px;
}

.title span {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-left: 10px;
}

/* tabs */
.tabs {
  /* width: 1336px; */
  background-color: rgb(4, 25, 66);
  display: flex;
}

.tabs li {
  width: 85px;
  height: 40px;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0);
  font-family: Microsoft YaHei;
  color: #e8f4ff;
  text-align: center;
  line-height: 40px;
}

.tabs .select {
  background-color: #002c6c;
  color: #ffc74a;
}

.tip {
  color: #e8f4ff;
  font-size: 12px;
  margin-left: 10px;
}

/* 查询表单 */
.query-form {
  display: flex;
  align-items: center;
  margin: 18px 0 20px 0;
}

.query-form :deep() .el-form {
  height: 40px;
}

.form :deep() .el-icon-date {
  color: #aed6ff;
}

.form :deep().el-form-item__label {
  color: #aed6ff;
  font-size: 12px;
}

.form :deep() .el-form-item {
  margin: 0;
  margin-right: 8px;
}

.hour :deep() .el-select {
  width: 70px;
  padding: 0;
}

.hour span {
  color: #aed6ff;
  margin: 0 5px 0 8px;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

/* input */
.form :deep() .el-input .el-input__inner {
  background-color: #061b45;
  border: 1px solid #09295b;
  color: #aed6ff;
  width: 100%;
}

/* 表格 */
/* 设置表头的背景颜色 */
.table :deep() thead th {
  background-color: rgb(9, 35, 78);
  color: #e8f4ff;
  font-size: 14px;
}

.table :deep() tbody tr {
  font-size: 14px;
  color: #aed6ff;
}

/* 设置表格内的背景颜色 */
::v-deep.el-table tbody tr:nth-child(odd) {
  background-color: #071734;
}

::v-deep.el-table tbody tr:nth-child(even) {
  background-color: #071734;
}

::v-deep.el-table {
  border: 2px solid #081f42 !important;
  position: relative;
}

::v-deep.el-table th {
  border: 1px solid #09234e !important;
}

::v-deep.el-table td {
  border: 1px solid #0d2247 !important;
}

.table ::v-deep .el-table__body tr:hover>td {
  background-color: #081f42 !important;
}

::v-deep .el-table::before {
  height: 0px;
}

::v-deep .el-table__fixed-right::before {
  height: 0px;
}

::v-deep .el-table__body tr.hover-row>td.el-table__cell {
  background-color: #081f42 !important;
}

/* 弹窗 */
::v-deep .el-dialog {
  background-color: #061d4a;
  font-size: 16px;
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid #09295b;
}

::v-deep .el-dialog__title {
  font-size: 16px;
  color: #fff;
}

#chart {
  width: 768px;
  height: 450px;
}

.dialog-footer {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #000;
}

.dialog-footer button {
  width: 100px;
  height: 35px;
  background: #2142a1;
  border: 0;
  color: #fff;
}

.dialog-content {
  font-size: 16px;
  color: #ebedf1;
}
/* 更改 el-table 横向滚动条的样式 */
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  height: 8px;
  /* 设置滚动条高度 */
  background-color: #09234E;
  /* 设置滚动条背景色 */
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #003d74;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}

</style>

